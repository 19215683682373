import CommonSkeleton from '@/components/CommonSkeleton';
import { FirstDepositDto } from '@/hooks/useFirstDepositBouns';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { IDeposit, IDepositCoupon } from '@/types/config';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { DepositBouns } from './DepositBouns';
import { findRuleByValue } from './utils';

interface Props {
  deposit: IDeposit | null;
  money: number;
  onChangeMoney: (amount: number) => void;
  selectedCoupon?: IDepositCoupon;
  firstDeposit?: FirstDepositDto | null;
  // onSelectFirstDeposit: (id: string) => void;
}

const checkMatchCoupon = (money: number, coupon?: IDepositCoupon) => {
  if (!coupon) {
    return false;
  }

  const isLessMin = money < coupon.min_money && coupon.min_money > 0;
  const isMoreMax = money > coupon.max_money && coupon.max_money > 0;

  return !isLessMin && !isMoreMax;
};

const getCouponValue = (money: number, coupon?: IDepositCoupon) => {
  if (!coupon) {
    return 0;
  }
  // 百分比
  if (coupon.reward_type === 1) {
    const value = (money * coupon.reward) / 100;
    return Math.min(value, coupon.max_reward_money);
  } else {
    return coupon.reward;
  }
};

const DepositAmounts: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');
  const [isHovered, setIsHovered] = useState(false);

  if (!props.deposit) {
    return <CommonSkeleton />;
  }

  if (props.deposit.money_list.length === 0) {
    return null;
  }
  const handleTouchEnd = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Typography mb={1.5} variant="subtitle2" fontWeight={700}>
        {__('Select deposit amount')}
      </Typography>
      <Grid container columns={24} spacing={1}>
        {props.deposit.money_list.map((value) => {
          const isSelected = +value === props.money;

          const rule = findRuleByValue(+value, props.firstDeposit?.rules);

          const bouns = rule?.rulePrizeValue ?? '';

          // const isMatchCoupon = checkMatchCoupon(+value, props.selectedCoupon);

          return (
            <Grid item xs={8} key={value}>
              <Box position="relative">
                <Button
                  variant="contained"
                  sx={[
                    {
                      height: '64px',
                      width: '100%',
                      borderRadius: '8px',
                      bgcolor: isSelected
                        ? 'primary.main'
                        : isMobile
                        ? 'background.neutral'
                        : 'background.paper',
                      '&:hover': {
                        boxShadow: '0 0 0 0',
                        backgroundColor:
                          isHovered || isSelected
                            ? 'primary.main'
                            : 'background.neutral',
                      },
                    },
                  ]}
                  disableFocusRipple={true}
                  disableRipple={true}
                  disableTouchRipple={true}
                  onClick={() => {
                    props.onChangeMoney(+value);
                    // props.onSelectFirstDeposit(rule?.activityId ?? '');
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onTouchEnd={handleTouchEnd}
                >
                  <Stack direction="row" alignItems="center">
                    <Typography mr={0.5} variant="body1" color="text.secondary">
                      R$
                    </Typography>
                    <Typography variant="h2">{value}</Typography>
                  </Stack>
                </Button>
                {/* 暂时注释掉 */}
                {/* {isMatchCoupon && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      px: '4px',
                      py: '2px',
                      bgcolor: 'secondary.main',
                      borderRadius: '0 8px',
                      fontSize: '12px',
                      lineHeight: 1,
                    }}
                  >
                    +{getCouponValue(+value, props.selectedCoupon)}
                  </Box>
                )} */}
                {props.firstDeposit?.userTripTimes ===
                props.firstDeposit?.activityTripTimes ? null : (
                  <DepositBouns bouns={bouns} />
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const ExtraMoney = (props: {
  money: number;
  coupon?: IDepositCoupon;
}) => {
  const isMatchCoupon = checkMatchCoupon(props.money, props.coupon);
  if (!isMatchCoupon) return null;

  return (
    <Typography variant="body1" color="secondary.main" fontWeight={'500'}>
      Extra+{getCouponValue(props.money, props.coupon)}
    </Typography>
  );
};

export const FirstDepostExtraMoney = (props: { money: string | number }) => {
  if (!props.money) return null;

  return (
    <Typography variant="body1" color="secondary.main" fontWeight={'500'}>
      Extra+{props.money}
    </Typography>
  );
};

export default DepositAmounts;
