import {
  ActivityType,
  HeartbeatMessageDto,
  InviteEventsInfoDto,
  ScrollMessageDto,
} from '@/types';
import { CommonResponse } from '@/types/response';
import { Request } from '../utils';
import { testHost } from './host';

const host = '';

export async function getInit(params: {
  media_id_list: string;
  news_id_list: string;
  parameter?: string;
}) {
  return Request.post(`${testHost}/api_load.html`, params);
}

// 获取充值券
export async function getRechargeCoupon() {
  try {
    const { data } = await Request.post(`${testHost}/api_pay_card.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

// 获取充值方式列表
export async function getDeopositMethods() {
  try {
    const { data } = await Request.post(`${testHost}/api_pay_load.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

// 获取充值方式列表
export async function getVipList() {
  try {
    const { data } = await Request.post(`${testHost}/api_vip_list.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

export async function heartbeat() {
  try {
    const { data } = await Request.post<{
      handle: string;
      data: {
        agent_money: number;
        message: HeartbeatMessageDto;
        money: number;
        no_dama_money: number;
      };
    }>(`${testHost}/api_heartbeat.html`);
    return data;
  } catch (error) {
    return null;
  }
}

// 获取佣金信息
export async function getCommissionInfo() {
  return Request.get(`${host}/api/allAgentNew/GetCommissionInfo`);
}

export async function getBoxInfoById(id: number) {
  return Request.get(`${host}/api/reward/get_box_by_id?box_id=${id}`);
}

export async function getOwnBoxes() {
  return Request.get(`${host}/api/reward/get_own_boxes`);
}

export async function openBox(params: {
  activityId: string;
  userActivityId: string;
  ruleKey: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_grant_acivity_reward.html`,
      params,
    );
    return data?.data as Array<{
      prizeName: {
        zhCN: string;
        enUS: string;
        ptBR: string;
      };
      pic: string;
    }>;
  } catch (error) {
    return null;
  }
}

// 获取活动列表
export async function getActivityList(
  /**
   * 1、签到活动；2、红包/兑换码活动；3、首充活动；4、单次充值活动；5、累计充值活动；6、邀新活动；7、打码量活动；8、排行榜互动
   */
  activityType?: ActivityType,
) {
  try {
    const { data } = await Request.get(`${testHost}/api_activity_list.html`, {
      params: {
        activityType,
      },
    });
    return data.data;
  } catch (error) {
    return null;
  }
}

// 获取活动列表V2
export async function getActivityListV2(
  /**
   * 1、签到活动；2、红包/兑换码活动；3、首充活动；4、单次充值活动；5、累计充值活动；6、邀新活动；7、打码量活动；8、排行榜互动
   */
  activityType?: ActivityType,
) {
  try {
    const { data } = await Request.get(
      `${testHost}/v2/api_activity_list.html`,
      {
        params: {
          activityType,
        },
      },
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

export async function getInviteRecords(params: {
  current: number;
  size: number;
}) {
  try {
    const { data } = await Request.get(`${testHost}/api_sub_user_list.html`, {
      params,
    });
    return data;
  } catch (error) {
    return null;
  }
}

export async function getActivityDetail(params: { activityId: string }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_activity_info.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}
// 获取邀新活动信息
export const getInviteBoxEventsInfo = async (sTime?: number) => {
  try {
    const { data } = await Request.post(
      `${testHost}/api_invitation_record.html`,
      {
        sTime,
      },
    );
    return data?.data as InviteEventsInfoDto;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// 获取活动列表
export async function getPromotionList(
  /**
   * 1、签到活动；2、红包/兑换码活动；3、首充活动；4、单次充值活动；5、累计充值活动；6、邀新活动；7、打码量活动；8、排行榜互动
   */
  activity_type?: ActivityType,
) {
  try {
    const { data } = await Request.post(`${testHost}/api_activity_list.html`, {
      activity_type,
    });
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 获取活动内容
 *
 * @export
 * @param {{
 *   activity_id: Number;活动id
 * }} params
 * @return {*}
 */
export async function getPromotionDetail(params: { activityId: string }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_activity_info.html`,
      params,
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 获取消息列表
 *
 * @export
 * @param {{
 *   page: Number;
 *   pagesize: Number;
 * }} params
 * @return {*}
 */
export async function getMessageAry(params: {
  page: number;
  pagesize: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_message_list.html`,
      params,
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 消息内容
 *
 * @export
 * @param {{
 *   message_id: Number;
 * }} params
 * @return {*}
 */
export async function getMessageInfo(params: { message_id: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_message_info.html`,
      params,
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 删除消息
 *
 * @export
 * @param {{
 *   message_id: Number;
 * }} params
 * @return {*}
 */
export async function delMessage(params: { message_id: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_message_delete.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

/**
 * 代理等级列表接口
 *
 * @export
 * @param {{ sid: number }} params
 * @return {*}
 */
export async function getAgentLevelInfo(params: { sid: string }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_agent_level_list.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

// 获取代理钱包总数据
export async function getAgentSummary() {
  try {
    const { data } = await Request.post(`${testHost}/api_agent_summary.html`);
    console.log(data);
    return data;
  } catch (error) {
    return null;
  }
}

// 获取游戏历史
export async function getGameHistory() {
  return Request.get(`${testHost}/api_history_record.html`);
}

// 奖励兑换
export async function redeemCode(code: string) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_process_activity.html`,
      {
        activityCode: code,
      },
    );
    return data;
  } catch (error) {
    return null;
  }
}

// 奖励兑换
export async function weekCardRecive(id: string) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_process_activity.html`,
      {
        activityId: id,
      },
    );
    return data;
  } catch (error) {
    return null;
  }
}

export async function getScrollMessage(rewardType: number) {
  const { data } = await Request.post<CommonResponse<Array<ScrollMessageDto>>>(
    `${testHost}/api_received_rewards.html`,
    {
      rewardType,
    },
  );
  return data.data;
}

// 获取佣金信息
export async function getPackageDetails(
  current: number,
  size: number,
  userActivityId: string,
) {
  return Request.get(
    `${testHost}/v2/apirecharge_package_details.html?current=${current}&size=${size}&userActivityId=${userActivityId}`,
  );
}
