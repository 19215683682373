import IMAGE_ADD from '@/assets/topnav/icon_add.svg';
import IMAGE_COIN from '@/assets/topnav/icon_money.svg';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useResponsive from '@/hooks/useResponsive';
import { isOpenDepositState } from '@/stores/auth';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import IcomImage from '../IconImage';

interface Props {}

const BalanceBar: React.FC<Props> = (props: Props) => {
  const { wallet, getWallet } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');
  const openDiposit = useSetRecoilState(isOpenDepositState);

  const handleRefresh = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await getWallet();
    setIsLoading(false);
  };

  const handleClickDeposit = () => {
    // if (isMobile) {
    //   navigate('/deposit');
    // } else {
    // }
    openDiposit(true);
  };

  return (
    <>
      <Center
        sx={{
          bgcolor: '#313D4D',
          height: '32px',
          borderRadius: '8px',
          pl: 1,
          pr: 0.5,
          cursor: 'pointer',
        }}
        onClick={handleRefresh}
      >
        <Stack direction="row" alignItems="center" spacing="4px">
          {isLoading ? (
            <CircularProgress size={16} color="info" />
          ) : (
            <IcomImage src={IMAGE_COIN} alt="coin" />
          )}
          <Typography variant="body1" mx={0.5}>
            {(+(wallet?.money || 0)).toFixed(2)}
          </Typography>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              minWidth: '24px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleClickDeposit();
            }}
          >
            <img
              src={IMAGE_ADD}
              alt=""
              style={{ width: '24px', height: '24px', minWidth: '24px' }}
            />
          </Box>
        </Stack>
      </Center>
    </>
  );
};

export default BalanceBar;
