import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { ColorfulBg } from '@/styles';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Stack } from '@mui/material';
interface Props {
  tab: string;
  tabs: string[];
  onClickTab: (tab: string, index: number) => void;
}

export const InviteTabButtons = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  const { __ } = useLang();
  const handleClick = (tab: string, index: number) => () => {
    props.onClickTab(tab, index);
  };

  return (
    <Box
      px={2}
      pb={1.5}
      pt={isMobile ? 0 : 2}
      sx={{
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          ...hideScrollbarX,
        }}
      >
        <Stack direction="row" spacing={1.5}>
          {props.tabs.map((item, index) => {
            const isActive = props.tab === item;
            return (
              <Box
                key={item}
                // variant="contained"
                onClick={handleClick(item, index)}
                sx={{
                  bgcolor: isActive ? ColorfulBg() : '#313D4D',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: isActive ? 4 : 2,
                  height: 40,
                  '&:hover': {
                    bgcolor: isActive ? ColorfulBg() : '#313D4D',
                  },
                  boxShadow: 'none !important',
                  color: isActive ? 'text.primary' : 'text.secondary',
                  fontWeight: isActive ? 700 : 'medium',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                }}
              >
                {__(`tab${item}`)}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};
